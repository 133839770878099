import LandingPageComponent from '../../component/landing';
import React from 'react';

const IndexPage = () => {
  return (
    <LandingPageComponent isUniversal={true} isFinal={true} isTerm={true} />
  );
};

export default IndexPage;
